@import "../../../sass/abstracts/variables";
@import "../../../sass/abstracts/mixins";

.whoWeAre {
  width: 100vw;
  min-height: 100vh;
  background: url("../../../assets/images/Whoweare_bgWhite.png");
  background-size: cover;
  color: $color-text-dark;

  .grid {
    max-width: 1400px;
    margin: 0 auto;
    display: grid;
    grid-template-rows: repeat(4, max-content);
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    padding: 0rem 5rem;

    &__heading {
      grid-row: 1 / 2;
      grid-column: 1 / -1;
      text-align: center;
      padding: 2rem 0rem;
      span {
        font-size: 3rem;
        position: relative;
        text-transform: uppercase;
        font-family: $font-heading;
        color: $color-text-grey-dark;

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 35%;
          left: -100px;
          width: 75px;
          height: 4px;
          background: $color-gold;
        }

        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 35%;
          right: -100px;
          width: 75px;
          height: 4px;
          background: $color-gold;
        }
      }
    }

    &__title {
      grid-row: 2 / 3;
      grid-column: 1 / 2;
      text-align: left;

      @include respond(phone) {
        grid-column: 1 / -1;
      }

      span {
        font-size: 7rem;
        position: relative;
        font-family: $font-heading;
        text-shadow: 5px 5px 2px rgba($color-black, 0.1);

        //text-transform: uppercase;
      }
    }

    &__description {
      grid-row: 3 / 4;
      grid-column: 1 / 2;
      text-align: left;

      padding: 2rem 0rem 5rem 0rem;
      font-size: 1.8rem;
      font-weight: 400;
      font-family: $font-paragraph;
      color: $color-text-grey-dark;

      @include respond(phone) {
        grid-column: 1 / -1;
      }
    }

    &__points {
      grid-row: 4 / 5;
      grid-column: 1 / -1;
      width: 80%; //? CHANGE THIS IF THE CONTAINER IS TOO WIDE
      margin: 0 auto;

      .pointLeft {
        float: left;
      }

      .pointRight {
        float: right;
      }

      .pointLeft,
      .pointRight {
        display: grid;
        grid-template-rows: repeat(2, max-content);
        grid-template-columns: max-content 35vw;
        grid-gap: 1.5rem;
        margin-bottom: 5rem;

        @include respond(phone) {
          grid-template-rows: repeat(3, max-content);
          grid-template-columns: 1fr;
        }

        &__imgContainer {
          grid-row: 1 / -1;
          grid-column: 1 / 2;
          display: flex;
          align-items: center;

          @include respond(phone) {
            grid-row: 1 / 2;
            grid-column: 1 / 2;
          }

          .icon {
            height: 15rem;
            width: 15rem;
          }
        }

        &__titleContainer {
          grid-row: 1 / 2;
          grid-column: 2 / 3;

          display: flex;
          align-items: center;

          font-size: 3rem;
          text-transform: uppercase;
          font-family: $font-heading;
          color: $color-dark-1;
          text-shadow: 3px 3px 2px rgba($color-black, 0.2);

          @include respond(phone) {
            grid-row: 2 / 3;
            grid-column: 1 / -1;
          }
        }

        &__descriptionContainer {
          grid-row: 2 / 3;
          grid-column: 2 / 3;

          display: flex;
          align-items: center;

          font-size: 1.8rem;
          font-family: $font-paragraph;
          color: $color-text-grey-dark;

          @include respond(phone) {
            grid-row: 3 / 4;
            grid-column: 1 / -1;
          }
        }
      }

      .pointRight {
        grid-template-columns: 35vw max-content;

        @include respond(phone) {
          grid-template-rows: repeat(3, max-content);
          grid-template-columns: 1fr;
        }

        &__imgContainer {
          grid-row: 1 / -1;
          grid-column: 2 / 3;

          @include respond(phone) {
            grid-row: 1 / 2;
            grid-column: 1 / -1;
          }
        }

        &__titleContainer {
          grid-row: 1 / 2;
          grid-column: 1 / 2;

          @include respond(phone) {
            grid-row: 2 / 3;
            grid-column: 1 / -1;
          }
        }

        &__descriptionContainer {
          grid-row: 2 / 3;
          grid-column: 1 / 2;

          @include respond(phone) {
            grid-row: 3 / 4;
            grid-column: 1 / -1;
          }
        }
      }

      .supportPoint {
        grid-template-columns: 35vw max-content;

        @include respond(phone) {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}

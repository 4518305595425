@import "../../sass/abstracts/variables";

.footer {
  background: $color-dark-1;
  border-top: 1px solid $color-light-2;
  width: 100vw;
  height: auto;
  color: $color-light-2;
  font-size: 1.4rem;
  font-weight: 300;
  padding: 2rem 5rem;
  display: flex;
  justify-content: space-between;

  .content {
    margin: 0 auto;
    width: 100%;
    max-width: 1400px;
    display: flex;
    justify-content: space-between;

    &__copyright {
      text-align: center;
      padding: 0.5rem 0rem;
      width: 100%;
      font-family: $font-paragraph;
    }

    &__developed {
      text-align: center;
      padding: 0.5rem 0rem;
      width: 100%;
      a {
        font-family: $font-paragraph;
        color: $color-light-2;
        text-decoration: none;

        &:hover {
         color: $color-light-1;
        }
      }
    }
    &__socials {
      text-align: center;
      padding: 0.5rem 0rem;
      display: flex;
      justify-content: center;
      flex-basis: 100%;

      .icon {
        height: 2rem;
        width: 2rem;
        margin: 0rem 2rem;
        fill: $color-light-2;
        transition: 0.3s ease;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
}

@import "../../../sass/abstracts/variables";
@import "../../../sass/abstracts/mixins";

.whatWeDo {
  width: 100vw;
  min-height: 100vh;
  background: url("../../../assets/images/Whatwedo_bgWhite.png");
  background-position: cover;
  color: $color-text-dark;
  padding-bottom: 7.5rem;
  .grid {
    max-width: 1400px;
    margin: 0 auto;
    display: grid;
    grid-template-rows: repeat(4, max-content);
    grid-template-columns: repeat(2, 1fr);
    //grid-gap: 2rem;
    padding: 0rem 5rem;

    @include respond(phone) {
      grid-template-rows: repeat(5, max-content);
      grid-template-columns: 1fr;
      grid-gap: 4rem;
    }

    &__heading {
      grid-row: 1 / 2;
      grid-column: 1 / -1;
      text-align: center;
      padding: 2rem 0rem;
      span {
        font-size: 2.5rem;
        position: relative;
        text-transform: uppercase;
        font-family: $font-heading;
        color: $color-text-grey-dark;

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 35%;
          left: -100px;
          width: 75px;
          height: 4px;
          background: $color-gold;
        }

        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 35%;
          right: -100px;
          width: 75px;
          height: 4px;
          background: $color-gold;
        }
      }
    }

    &__title {
      grid-row: 2/3;
      grid-column: 1/2;
      display: flex;
      align-items: center;

      font-size: 7rem;
      font-family: $font-heading;
      text-shadow: 5px 5px 2px rgba($color-black, 0.1);

      @include respond(phone) {
        grid-row: 2/3;
        grid-column: 1/2;
      }
    }

    &__description {
      grid-row: 3/4;
      grid-column: 1/2;
      font-size: 1.8rem;
      color: $color-text-grey-dark;
      font-family: $font-paragraph;

      @include respond(phone) {
        grid-row: 4/5;
        grid-column: 1/2;
      }
    }

    &__image {
      grid-row: 2 / span 2;
      grid-column: 2/3;
      margin: 0 auto;

      @include respond(phone) {
        grid-row: 3/4;
        grid-column: 1/2;
      }
      img {
        width: 30vw;
        box-shadow: 5px 5px 5px rgba($color-black, 0.3);

        @include respond(phone) {
          width: 80vw;
        }
      }
    }

    &__points {
      grid-row: 4/5;
      grid-column: 1/ -1;
      // display: flex;
      // justify-content: space-between;
      margin: 0 auto;
      display: grid;
      grid-template-rows: repeat(2, max-content);
      grid-template-columns: repeat(2, 30vw);
      grid-gap: 8rem;

      padding-top: 10rem;

      @include respond(phone) {
        grid-row: 5/6;
        grid-column: 1/2;

        display: grid;
        grid-template-rows: repeat(3, 25rem);
        grid-template-columns: 1fr;
        grid-gap: 5rem;
      }

      .point {
        position: relative;
        width: 25vw;
        height: auto;
        padding: 5rem 2rem 5rem 2rem;

        display: grid;
        grid-template-rows: 4.5rem max-content;
        grid-template-columns: 1fr;

        box-shadow: 5px 4px 10px rgba($color-black, 0.5);

        @include respond(big-desktop) {
          width: 30vw;
          grid-template-rows: 4.5rem max-content;
        }

        @include respond(tab-land) {
          width: 26vw;
        }

        @include respond(tab-port) {
          width: 28vw;
          grid-template-rows: 7rem max-content;
        }

        @include respond(phone) {
          width: 100%;
        }

        &__1 {
          background: $pointBox1-bg-color;
        }
        &__2 {
          background: $pointBox2-bg-color;
        }
        &__3 {
          background: $pointBox3-bg-color;
        }
        &__4 {
          background: $pointBox4-bg-color;
        }

        &__icon {
          position: absolute;
          top: -3.5rem;
          left: 2rem;
          width: 6.5rem;
          height: 6.5rem;
          z-index: 20;
          opacity: 100%;
        }

        &__title {
          font-size: 2.5rem;
          text-transform: uppercase;
          position: relative;
          z-index: 10;
          text-align: left;
          font-family: $font-heading;
          text-shadow: 2px 2px 2px rgba($color-black, 0.2);
        }

        &__description {
          font-size: 1.4rem;
          font-weight: 400;
          padding-top: 0rem;
          position: relative;
          z-index: 10;
          text-align: left;
          color: $color-text-grey-dark;
          font-family: $font-paragraph;
          hyphens: auto;
        }
      }
    }
  }
}

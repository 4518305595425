@import "../../../sass/abstracts/variables";
@import "../../../sass/base/animations";
@import "../../../sass/abstracts/mixins";

.howWeDoIt {
  width: 100vw;
  //min-height: 100vh;
  background: url("../../../assets/images/howWeDoIt_bg.png");
  background-size: cover;
  color: $color-text-light;
  padding: 7.5rem 0rem;

  .grid {
    max-width: 1400px;
    margin: 0 auto;
    display: grid;
    grid-template-rows: repeat(3, max-content) min-content max-content;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    padding: 0rem 5rem;

    &__title {
      grid-row: 2 / 3;
      grid-column: 1 / -1;
      text-align: left;

      span {
        font-size: 7rem;
        position: relative;
        font-family: $font-heading;
        text-shadow: 5px 5px 2px rgba($color-black, 0.4);

        //text-transform: uppercase;
      }
    }

    &__description {
      grid-row: 3 / 4;
      grid-column: 1 / -1;
      text-align: left;

      padding: 0rem 0rem;
      font-size: 4rem;
      font-weight: 400;
      color: $color-gold;
      font-family: $font-slogan;
      text-shadow: 5px 5px 2px rgba($color-black, 0.4);
    }

    .steps {
      grid-row: 6 / 7;
      grid-column: 1 / -1;

      display: grid;
      grid-template-rows: repeat(3, max-content);
      grid-template-columns: repeat(2, 1fr);

      @include respond(phone) {
        grid-template-rows: repeat(6, max-content);
        grid-template-columns: 1fr;
        grid-gap: 2rem;
      }

      //grid-gap: 2rem;
      //* GENERAL STYLING FOR ALL STEPS
      &__imageContainer1,
      &__imageContainer2,
      &__imageContainer3 {
        display: flex;
        justify-content: center;
        align-items: center;
        .img {
          height: 30rem;
          width: 40rem;
        }
      }

      &__step1,
      &__step2,
      &__step3 {
        display: flex;
        justify-content: center;
        align-items: center;
        .stepText {
          font-size: 1.8rem;
          line-height: 1.5;
          font-family: $font-paragraph;
          color: $color-text-light;

          span {
            text-transform: uppercase;
            font-size: 5.5rem;
            font-weight: 700;
            font-family: $font-heading;
            color: $color-gold;
            text-shadow: 5px 5px 2px rgba($color-black, 0.4);

            .likeIcon {
              fill: $color-gold;
              height: 4.5rem;
              width: 4.5rem;
              transform: translateY(3px);
              margin-right: 2rem;
            }
          }
        }
      }

      // GRID POSITIONING
      &__imageContainer1 {
        grid-row: 1/ 2;
        grid-column: 1/ 2;

        @include respond(phone) {
          grid-row: 1/ 2;
          grid-column: 1/ -1;
        }
      }
      &__step1 {
        grid-row: 1/ 2;
        grid-column: 2 / 3;

        @include respond(phone) {
          grid-row: 2 / 3;
          grid-column: 1/ -1;
        }
      }

      &__imageContainer2 {
        grid-row: 2 / 3;
        grid-column: 2 / 3;

        @include respond(phone) {
          grid-row: 3 / 4;
          grid-column: 1/ -1;
        }
      }
      &__step2 {
        grid-row: 2 / 3;
        grid-column: 1/ 2;

        @include respond(phone) {
          grid-row: 4 / 5;
          grid-column: 1/ -1;
        }
      }

      &__imageContainer3 {
        grid-row: 3 / 4;
        grid-column: 1/ 2;

        @include respond(phone) {
          grid-row: 5 / 6;
          grid-column: 1/ -1;
        }
      }
      &__step3 {
        grid-row: 3 / 4;
        grid-column: 2 / 3;

        @include respond(phone) {
          grid-row: 6 / 7;
          grid-column: 1/ -1;
        }
      }
    }
  }
}

@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";

.hero {
  width: 100vw;
  height: 100vh;
  background: url("../../assets/images/Hero_BG.png");
  background-position: center;
  background-size: cover;
  color: $color-text-light;
  overflow: hidden;
  padding-top: 3rem;

  @include respond(big-desktop) {
    padding-top: 10rem;
  }

  @include respond(phone) {
    padding-top: 25rem;
  }

  .content {
    display: grid;
    grid-template-rows: repeat(4, max-content) 1fr;
    grid-template-columns: repeat(2, 1fr);
    position: relative;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;

    &__title {
      padding-top: 6rem;
      font-size: 12rem;
      text-align: center;
      grid-row: 1 / 2;
      grid-column: 1 / span 2;
      font-family: $font-name;
      position: relative;
      z-index: 10;
      text-shadow: 5px 7px 2px rgba($color-black, 0.4);

      span {
        margin-left: -1.5rem;
        color: $color-red-1;
        position: relative;
        z-index: 10;
      }
    }

    &__slogan {
      padding-top: 1rem;
      font-size: 5.5rem;
      letter-spacing: 0px;
      flex: 1 0 100%;
      text-align: center;
      color: $color-gold;
      grid-row: 2 / 3;
      grid-column: 1 / span 2;
      font-family: $font-slogan;
      font-weight: 400;
      font-style: italic;
      position: relative;
      z-index: 10;
      text-shadow: 5px 7px 4px rgba($color-black, 0.7);

      @include respond(phone) {
        padding-top: 5rem;
      }
    }

    &__description {
      padding-top: 3rem;
      font-size: 2.5rem;
      width: 80%;
      margin: 0 auto;
      text-align: center;
      grid-row: 3 / 4;
      grid-column: 1 / span 2;
      font-family: $font-heading;
      letter-spacing: 2px;
      font-weight: 400;
      //text-transform: uppercase;
      color: $color-text-grey;
      position: relative;
      z-index: 10;
      text-shadow: 5px 3px 2px rgba($color-black, 0.7);

      @include respond(phone) {
        padding-top: 5rem;
      }
    }

    .buttonContact {
      padding-top: 10rem;
      margin-left: 5rem;
      //background: red;
      flex: 1 0 50%;
      display: flex;
      justify-content: space-around;
      position: relative;
      z-index: 10;

      @include respond(phone) {
        margin-left: 0rem;
      }

      &__border {
        width: 25rem;
        height: 6rem;

        background: $hero-button-gradient;

        background-size: 400%;
        background-position: left;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.5s ease;
        position: relative;
        z-index: 10;
      }

      &__container {
        width: 24.4rem;
        height: 5.5rem;
        transform: translate(-0.3px, 0px);

        background: #1c2044;

        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 10;
      }

      &__contact {
        width: 25rem;
        height: 6rem;
        border: none;
        background: $hero-button-gradient;
        background-size: 400%;
        background-position: left;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 2.5rem;
        font-weight: 700;
        font-family: $font-name;
        text-transform: uppercase;

        display: flex;
        justify-content: center;
        align-items: center;

        transition: 0.5s ease;
        position: relative;
        z-index: 10;
      }

      &__contact:hover,
      &__border:hover {
        background-position: right;
        transform: scale(1.05);
      }
    }

    .buttonServices {
      padding-top: 10rem;
      margin-right: 5rem;
      //background: red;
      flex: 1 0 50%;
      display: flex;
      justify-content: space-around;
      position: relative;
      z-index: 10;

      @include respond(phone) {
        margin-right: 0rem;
      }

      &__border {
        width: 25rem;
        height: 6rem;
        background: $hero-button-gradient;
        background-size: 400%;
        background-position: left;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.5s ease;
        position: relative;
        z-index: 10;
      }

      &__container {
        width: 24.4rem;
        height: 5.5rem;
        transform: translate(-0.3px, 0px);

        background: $hero-btn-bg;

        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 10;
      }

      &__services {
        width: 25rem;
        height: 6rem;
        border: none;
        background: $hero-button-gradient;

        background-size: 400%;
        background-position: left;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 2.5rem;
        font-weight: 700;
        font-family: $font-name;
        text-transform: uppercase;

        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 10;

        transition: 0.5s ease;
      }

      &__services:hover,
      &__border:hover {
        background-position: right;
        transform: scale(1.05);
      }
    }
  }
}

@import "../../../sass/abstracts/variables";
@import "../../../sass/base/animations";
@import "../../../sass/abstracts/mixins";

.leaveSatisfied {
  width: 100vw;
  height: auto;
  background: linear-gradient(
      to bottom,
      rgba($bg-blue-1, 0.95),
      rgba($bg-blue-2, 0.95)
    ),
    url("../../../assets/images/drill.jpg");
  background-size: cover;
  background-position: center;
  color: $color-text-light;

  .content {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: 15rem;
    padding: 5rem 5rem 0rem 5rem;

    @include respond(tab-port) {
      padding: 5rem 2rem 0rem 2rem;
    }

    @include respond(phone) {
      display: grid;
      grid-template-rows: repeat(3, 1fr);
      grid-template-columns: 1fr;
      grid-gap: 3rem;
    }

    .containerBorder {
      background: $golden-shine-gradient;
      padding: 0.3rem;

      background-size: 225%;
      animation: gradientSlide 3s infinite;
      animation-direction: alternate;
      box-shadow: 6px 8px 5px rgba($color-black, 0.4);

      @include respond(tab-port) {
        width: 50%;
        margin: 0 auto;
      }

      .container {
        width: auto;
        background: $bg-blue-1;

        padding: 2rem;

        display: grid;
        grid-template-rows: max-content max-content;
        grid-template-columns: max-content min-content;

        &__image {
          grid-row: 1 / -1;
          grid-column: 1/ 2;
        }

        &__icon {
          height: 10rem;
          width: 10rem;
          fill: $color-gold;
          margin-right: 2.5rem;
        }

        &__amount {
          grid-row: 1 / 2;
          grid-column: 2/3;
          text-align: center;
          font-size: 4rem;
          font-weight: 700;
          font-family: $font-name;
        }

        &__title {
          grid-row: 2 / 3;
          grid-column: 2/3;
          text-align: center;
          font-size: 2.5rem;
          letter-spacing: 3px;
          font-family: $font-heading;
          text-transform: uppercase;
          font-weight: 700;
          background: $golden-shine-gradient;

          background-size: 400%;
          background-position: left;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          animation: gradientSlide 3s infinite;
          animation-direction: alternate;
        }
      }
    }
  }

  .slogan {
    text-align: center;
    padding-top: 4rem;
    font-size: 4.5rem;
    font-family: $font-slogan;
    background: $golden-shine-gradient;
    background-size: 400%;
    background-position: left;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradientSlide 3s infinite;
    animation-direction: alternate;
  }

  .buttonContainer {
    padding: 2rem 0rem 6rem 0rem;
    display: flex;
    justify-content: center;

    .buttonContact {
      padding-top: 0rem;
      flex: 1 0 50%;
      display: flex;
      justify-content: space-around;

      &__border {
        width: 50rem;
        height: 6rem;
        background: $golden-shine-gradient;
        background-size: 400%;
        background-position: left;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.5s ease;
      }

      &__container {
        width: 49.5rem;
        height: 5.5rem;
        transform: translate(-0.3px, 0.2px);
        background: $bg-blue-2;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__contact {
        width: 25rem;
        height: 6rem;
        border: none;
        background: transparent;
        background: $golden-shine-gradient;
        background-size: 400%;
        background-position: left;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 2.5rem;
        font-weight: 700;
        font-family: $font-name;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;

        transition: 0.5s ease;
      }

      &__contact:hover,
      &__border:hover {
        background-position: right;
        transform: scale(1.05);
      }
    }
  }
}

@import "./sass/abstracts/variables";
@import "./sass/abstracts/functions";
@import "./sass/abstracts/mixins";

@import "./sass/base/base";
@import "./sass/base/animations";
@import "./sass/base/utilities";

.App {
  width: 100vw;
  overflow: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: $color-text-light;

  //-webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

//* COLORS

//TODO // MAIN COLORS

$color-black: #0a0a0a;
$color-white: #eeeeee;

$color-gold: #ffd600; //? GOLD WEB GOLDEN

$color-dark-1: #2b2d42; //? SPACE CADET
$color-dark-2: #707f99; //? SHADOW BLUE

$color-light-1: #edf2f4; //? ALICE BLUE
$color-light-2: #8d99ae; //? MANATEE

$color-red-1: #d90429; //? AMARANTH RED
$color-red-2: #ef233c; //? IMEPERIA
$color-red-3: #8c031a;

//TODO // TEXT COLORS

$color-text-light: #edf2f4; //? ALICE BLUE
$color-text-dark: #2b2d42; //? SPACE CADET
$color-text-grey: #8d99ae; //? MANATEE
$color-text-grey-dark: #505050;

//TODO // GRADIENT COLORS

$nav-hover-gradient: linear-gradient(
  90deg,
  rgba(238, 238, 238, 1) 0%,
  rgba(238, 238, 238, 1) 34%,
  rgba(252, 255, 74, 1) 51%,
  rgba(255, 36, 36, 1) 70%
);

$hero-button-gradient: linear-gradient(
  90deg,
  rgba(238, 238, 238, 1) 0%,
  rgba(0, 245, 232, 1) 30%,
  rgba(255, 36, 36, 1) 60%,
  rgba(252, 255, 74, 1) 80%
);

$golden-shine-gradient: linear-gradient(
  90deg,
  rgba(240, 228, 24, 1) 0%,
  rgba(255, 255, 255, 1) 34%,
  rgba(255, 242, 18, 1) 55%,
  rgba(255, 187, 63, 1) 71%,
  rgba(240, 228, 24, 1) 91%
);

$placeholder-shine-gradient: linear-gradient(
  90deg,
  rgba(110, 110, 110, 1) 0%,
  rgb(169, 169, 169) 24%,
  rgba(185, 185, 185, 1) 52%,
  rgba(217, 217, 217, 1) 75%,
  rgba(140, 138, 138, 1) 89%
);

$send-button-gradient: linear-gradient(
  90deg,
  rgba(255, 255, 255, 1) 0%,
  rgba(249, 247, 226, 1) 53%,
  rgba(240, 228, 24, 1) 89%
);

//TODO // HERO COLORS

$hero-btn-bg: #1c2044;

//TODO // WHAT WE DO COLORS
$pointBox1-bg-color: #8fc9ff;
$pointBox2-bg-color: #fff6a6;
$pointBox3-bg-color: #ff9999;
$pointBox4-bg-color: #d299ff;

//TODO // ALWAYS LEAVE SATISFIED COLORS

$bg-blue-1: #092260;
$bg-blue-2: #00154c;

//TODO // CONTACT US COLORS

$contact-bg-color: #191e48;

//* FONTS

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arvo:wght@400;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Cookie&display=swap");
$font-name: "Lato", sans-serif;
//$font-slogan: "Permanent Marker", cursive;
$font-slogan: "Cookie", cursive;
$font-heading: "Bebas Neue", cursive;
$font-paragraph: "Arvo", serif;

@import "../../sass/abstracts/mixins";

#phoneNumberNotif,
#emailNotif,
#fbNotif,
#instaNotif,
#twitterNotif,
#youtubeNotif {
  transition: 0.3s ease;
  //fill: orange;
  &:hover {
    transform: scale(1.05) translate(0.5rem, -1.25rem);
  }
}

.contactSVGG {
  @include respond(tab-land) {
    height: 50rem;
  }
}

@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";

.ourClients {
  width: 100vw;
  height: auto;
  background: linear-gradient(
      to bottom,
      rgba($bg-blue-1, 0.9),
      rgba($bg-blue-2, 0.9)
    ),
    url("../../assets/images/texture_001.jpg");
  color: $color-text-light;
  padding: 2.5rem 0rem 2.5rem 0rem;

  .pageGrid {
    max-width: 1400px;
    margin: 0 auto;
    display: grid;
    grid-template-rows: repeat(4, max-content);
    grid-template-columns: 1fr;
    grid-gap: 0rem;

    &__heading {
      grid-row: 1 / 2;
      grid-column: 1 / -1;
      text-align: center;
      padding: 2rem 0rem;
      span {
        font-size: 3rem;
        position: relative;
        text-transform: uppercase;
        font-family: $font-heading;
        color: $color-text-light;

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 35%;
          left: -100px;
          width: 75px;
          height: 4px;
          background: $color-gold;
        }

        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 35%;
          right: -100px;
          width: 75px;
          height: 4px;
          background: $color-gold;
        }
      }
    }

    &__description {
      grid-row: 2 / 3;
      grid-column: 1 / -1;
      text-align: center;

      padding: 5rem 0rem 5rem 0rem;

      h2 {
        font-size: 3rem;
        text-transform: uppercase;
        font-family: $font-heading;
        letter-spacing: 3px;
        color: $color-text-light;
        text-shadow: 5px 5px 2px rgba($color-black, 0.4);
      }
    }

    &__enquire {
      grid-row: 4 / 5;
      grid-column: 1 / -1;
      text-align: center;

      h3 {
        font-size: 2rem;
        padding-top: 3rem;
        font-weight: 400;
        font-family: $font-paragraph;
        text-shadow: 5px 5px 2px rgba($color-black, 0.4);

        .contactLink {
          color: $color-gold;
          text-decoration: none;

          &:hover {
            filter: brightness(105%);
            text-decoration: underline;
          }
        }
      }
    }

    .marquee {
      grid-row: 3 / 4;
      grid-column: 1 / -1;
      //max-width: 1400px;
      background: white; //! This cannot change as some logos have a white background
      overflow: hidden;
      white-space: nowrap;
      font-size: 144px;
      padding: 2rem 0rem 0rem 0rem;

      @include respond(tab-land) {
        transform: translateX(-200px) scale(0.8);
        width: 150%;
      }

      @include respond(phone) {
        transform: translateX(-100px) scale(0.8);
        width: 150%;
      }
    }

    .tracks {
      transform: translateX(-110%);
      .track1 {
        display: inline-block;
        animation: marquee1 30s linear infinite;
        .logoContainer {
          display: inline-block;
          margin: 0rem 2rem;

          .logo {
            height: 150px;
            object-fit: cover;
          }
        }
      }

      .track2 {
        display: inline-block;
        animation: marquee2 30s linear infinite;
        animation-delay: 15s;
        .logoContainer {
          display: inline-block;
          margin: 0rem 2rem;

          .logo {
            height: 150px;
            object-fit: cover;
          }
        }
      }

      .content {
        display: inline-block;
      }
    }

    @keyframes marquee1 {
      from {
        transform: translateX(100%);
      }
      to {
        transform: translateX(-100%);
      }
    }

    @keyframes marquee2 {
      from {
        transform: translateX(0%);
      }
      to {
        transform: translateX(-200%);
      }
    }

    .slideShowContainer {
      padding: 2rem;

      .company {
        display: flex;
        align-content: flex-start;
        height: 200px;
        width: 100%;
        //TODO: Crystal Clear -- Glass & Aluminium
        &__1 {
          margin: 0rem 2rem;
          display: flex;
          align-content: center;
        }

        //TODO: G.James -- Aluminium Products
        &__2 {
          margin: 0rem 2rem;
          display: flex;
          align-content: center;
        }

        //TODO: Ullrich Aluminium
        &__3 {
          margin: 0rem 2rem;
          display: flex;
          align-content: center;
        }

        //TODO: Bradnam's -- Windows & Doors
        &__4 {
          margin: 0rem 2rem;
          display: flex;
          align-content: center;
        }

        //TODO: Wynstan - Blinds Doors Shutters Awnings
        &__5 {
          margin: 0rem 2rem;
          display: flex;
          align-content: center;
        }

        //TODO: Wideline -- Windows & Doors
        &__6 {
          margin: 0rem 2rem;
          display: flex;
          align-content: center;
        }

        //TODO: Trend -- Windows & Doors
        &__7 {
          margin: 0rem 2rem;
          display: flex;
          align-content: center;
        }

        //TODO: Custom -- Aluminium Windows
        &__8 {
          margin: 0rem 2rem;
          display: flex;
          align-content: center;
        }
        //TODO: Colonial Castings
        &__9 {
          margin: 0rem 2rem;
          display: flex;
          align-content: center;
        }
      }
    }
  }
}

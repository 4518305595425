@import "../../sass/abstracts/mixins";
@import "../../sass/abstracts/variables";

.toolbar {
  display: none;
}

.overlay {
  display: none;
}

@include respond(phone) {
  .toolbar {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 60%;
    height: 100vh;
    background: $color-dark-1;
    border-right: 1px solid $color-dark-2;
    z-index: 200;
    transition: 0.3s ease;
  }

  .open {
    transform: translateX(0);
  }

  .close {
    transform: translateX(-100%);
  }

  .overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba($color-black, 0.7);
    z-index: 180;
    transition: 0.3s ease;
    opacity: 100%;
  }
  .remove {
    opacity: 0%;
    z-index: -1;
  }

  .logoContainer {
    position: relative;
    z-index: 205;
    //background: orange;
    border-bottom: 1px solid $color-dark-2;
    //padding: 15rem;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .logoToolbar {
      position: relative;
      z-index: 210;
      width: 50%;
      height: 50%;
     // background: yellow;
    }
  }

  .navList {
    //@include: orange;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 60vh;
    .navItem {
      font-size: 8rem;
      padding: 2rem 0rem;
      font-family: $font-heading;
      color: $color-text-light;
    }
  }
}

//? ANIMATIONS
@keyframes gradientSlide {
  0% {
    //background: blue;
    background-position: left;
  }
  100% {
    //background: red;
    background-position: right;
  }
}

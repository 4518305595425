@import "../../sass/abstracts/variables";
@import "../../sass/abstracts/mixins";

.navigation {
  position: absolute;
  color: $color-text-light;
  display: flex;
  height: auto;
  padding: 0.5rem 5rem;
  width: 100vw;
  height: 60px;
  overflow: hidden;
  //background: orange;
  @include respond(phone) {
    display: none;
  }

  .logoContainer {
    width: 17.5vw;
    display: inline-block;
    transform: translateY(-10px);
    @include respond(tab-land) {
      transform: translateY(-5px);
    }

    .logoIcon {
      padding: 0px;
      height: 100%;
      @include respond(tab-land) {
        width: 20%;
      }
    }
    .logoText {
      margin: 0px 0px 0px 15px;
      height: auto;
      @include respond(tab-land) {
        width: 50%;
      }
    }
  }

  .navList {
    list-style: none;
    display: flex;
    width: 78vw;
    justify-content: space-around;
    align-items: center;
    font-size: 2.5rem;
    text-transform: uppercase;

    @include respond(phone) {
      display: none;
    }

    .navItem {
      padding: 1rem 2.5rem;
      position: relative;
      z-index: 20;

      &:hover span {
        background-position: right;
      }

      .navLink {
        span {
          background: $nav-hover-gradient;
          background-size: 400%;
          background-position: left;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-family: $font-heading;
          font-weight: 300;
          transition: 0.2s ease;
          &:hover {
            background-position: right;
          }
        }
      }
    }
  }
}

.navigationMob {
  position: absolute;
  top: 0;
  left: 0;
  //background: orange;
  width: 100vw;
  height: 75px;
  display: none;

  @include respond(phone) {
    display: block;
  }

  .toggleBtn {
    height: 60px;
    width: 60px;
    margin: 1.5rem;
    color: $color-text-light;
  }
}

@import "../../sass/abstracts/variables";
@import "../../sass/base/animations";
@import "../../sass/abstracts/mixins";

.contactUs {
  width: 100vw;
  height: auto;
  background: url("../../assets/images/Hero_BG.png");
  background-position: center;
  background-size: cover;
  padding: 2rem 5rem 3.5rem 5rem;

  .contactContainer {
    max-width: 1400px;
    height: auto;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 3px;
    box-shadow: 5px 5px 8px rgba($color-black, 0.3);

    display: flex;

    @include respond(tab-port) {
      flex-direction: column;
    }

    .overlay {
      width: 50%;
      height: 100%;
      background: rgba($contact-bg-color, 1);
      float: left;

      @include respond(tab-port) {
        width: 100%;
      }
    }

    .grid {
      width: 100%;
      display: grid;
      grid-template-rows: repeat(8, max-content);
      grid-template-columns: 1fr 1fr;
      grid-gap: 0rem;
      color: $color-text-light;
      padding: 2rem 10rem;

      @include respond(phone) {
        padding: 2rem 1rem;
      }

      &__heading {
        grid-row: 1 / 2;
        grid-column: 1 / -1;
        text-align: center;
        padding: 2rem 0rem;
        span {
          font-size: 3rem;
          position: relative;
          text-transform: uppercase;
          font-family: $font-heading;

          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 35%;
            left: -60px;
            width: 50px;
            height: 4px;
            background: $color-gold;
          }

          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 35%;
            right: -60px;
            width: 50px;
            height: 4px;
            background: $color-gold;
          }
        }
      }

      &__description {
        grid-row: 2 / 3;
        grid-column: 1 / -1;
        text-align: center;

        padding: 0rem 0rem;
        font-size: 1.6rem;
        font-family: $font-paragraph;
        color: $color-text-grey;
      }

      &__getInTouch {
        grid-row: 3 / 4;
        grid-column: 1 / -1;
        text-align: center;
        padding: 2rem 0rem;
        font-size: 3.2rem;
        letter-spacing: 2px;
        text-transform: uppercase;
        font-family: $font-heading;

        background: $golden-shine-gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: 225%;
        animation: gradientSlide 3s infinite;
        animation-direction: alternate;
      }

      .formContainer {
        grid-row: 4 / 5;
        grid-column: 1 / -1;

        display: grid;
        grid-template-rows: repeat(5, max-content);
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;

        .error, .success {
          grid-column: 1 / -1;
          font-size: 1.4rem;
          font-family: $font-paragraph;
        } 

        .error {
          color: $color-red-2;
        }

        .success { 
          color: rgb(71, 211, 15);
        }


        .nameInput,
        .phoneInput,
        .emailInput,
        .messageInput {
          outline: none;
          font-family: $font-paragraph;

          color: $color-light-1;

          &::placeholder {
            color: $color-text-grey;
          }
          
        }

        .borderContainer {
          position: relative;
          height: 100%;
          vertical-align: bottom;

          &::before {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            height: 2px;
            width: 100%;
            background: $golden-shine-gradient;
            background-size: 225%;
            animation: gradientSlide 3s infinite;
            animation-direction: alternate;
          }
        }

        .borderFullContainer {
          position: relative;
          height: 100%;
          vertical-align: bottom;

          width: 100%;
          background: $golden-shine-gradient;
          background-size: 225%;
          animation: gradientSlide 3s infinite;
          animation-direction: alternate;
          padding: 0.1rem;
          box-shadow: 2px 4px 5px rgba($color-black, 0.2);

          &__sendButton {
            grid-row: 4 / 5;
            grid-column: 1 / -1;

            border-radius: 0px;
            transition: 0.3s ease;
            &:hover {
              transform: translate(3px, -3px);
              box-shadow: 1px 5px 5px rgba($color-black, 0.2);
            }

            &:active {
              transform: translate(1px, -1px);
              box-shadow: 2px 5px 5px rgba($color-black, 0.2);
            }
          }
        }

        .nameInputContainer {
          grid-row: 1 / 2;
          grid-column: 1 / 2;

          .nameInput {
            font-size: 1.8rem;
            padding: 0.5rem 1rem;
            width: 100%;

            background: transparent;
            border: none;
            position: relative;
          }
        }

        .phoneInputContainer {
          grid-row: 1 / 2;
          grid-column: 2 / 3;

          .phoneInput {
            font-size: 1.8rem;
            padding: 0.5rem 1rem;

            width: 100%;

            background: transparent;
            border: none;
            border-bottom: 2px solid $color-gold;
          }
        }

        .emailInputContainer {
          grid-row: 2 / 3;
          grid-column: 1 / -1;

          .emailInput {
            font-size: 1.8rem;
            padding: 0.5rem 1rem;

            width: 100%;

            background: transparent;
            border: none;
            border-bottom: 2px solid $color-gold;
          }
        }

        .messageInputContainer {
          grid-row: 3 / 4;
          grid-column: 1 / -1;

          .messageInput {
            font-size: 1.8rem;
            padding: 0.5rem 1rem;
            width: 100%;
            height: 15rem;
            background: $contact-bg-color;
            resize: none;
            border: none;
            outline: none;
            transform: translateY(1px);
          }
        }

        .sendButton {
          width: 100%;

          padding: 1.5rem 2rem;

          outline: none;

          text-transform: uppercase;
          border: none;
          border-radius: 0px;

          background: $contact-bg-color;

          transition: 0.2s ease;
          font-family: $font-paragraph;

        
            font-size: 1.8rem;
            font-weight: 700;
            font-family: $font-name;

            color: $color-gold;

            // //background: $send-button-gradient;
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
            // background-size: 225%;
            // background-position: left;
            // transition: 0.3s ease;
          
            :hover {
              background-position: right;
            }
        }
      }

      &__youCanFindUsOn {
        grid-row: 6 / 7;
        grid-column: 1 / -1;
        text-align: center;
        padding: 1rem 0rem;
      }

      &__socials {
        grid-row: 7 / 8;
        grid-column: 1 / -1;
        display: flex;
        justify-content: space-evenly;
        padding: 2rem 0rem;

        .socialIcon {
          height: 4rem;
          width: 4rem;
          transition: 0.2s ease;

          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }

    .rightContainer {
      width: 50%;
      background: linear-gradient(
          to bottom,
          rgba($contact-bg-color, 0.85),
          rgba($contact-bg-color, 0.85)
        ),
        url("../../assets/images/sydney_001.jpg");
      background-position: right;

      display: flex;
      justify-content: center;
      align-items: center;

      @include respond(tab-port) {
        padding: 5rem 0rem;
        width: 100%;
      }
      .contactSVGContainer {
      }
    }
  }

  .topButton {
    background: $golden-shine-gradient;
    background-size: 225%;
    animation: gradientSlide 3s infinite;
    animation-direction: alternate;
    margin: 0 auto;
    margin-top: 5rem;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    padding: 0.2rem;
    position: relative;
    transition: 0.3s ease;

    &::before {
      content: "Back To Top";
      display: block;
      position: absolute;
      bottom: -2.5rem;
      left: -2rem;
      color: $color-text-light;
      font-size: 1.6rem;
      width: 190%;
      transition: 0.3s ease;
      transform: translateY(-25px);
      opacity: 0%;
      //background: red;
    }

    &:hover::before {
      transform: translateY(0px);
      opacity: 100%;
    }

    &:hover {
      transform: scale(1.05);
    }

    .arrowContainer {
      width: 4.5rem;
      height: 4.5rem;
      background: $contact-bg-color;
      border-radius: 50%;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .upArrow {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: $color-gold;
        height: 2.5rem;
      }
    }
  }
}

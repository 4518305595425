*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // ** This Defines what 1rem is === 10px //
  font-size: 62.5%; //! Default Root Size 16px so 100% is 16px therefore 62.5% = 10px
  // ** 1rem = 10px;

  // Order of media queries is important!

  @include respond(tab-land) {
    // width < 1200?
    //font-size: 56.25%; // 1 rem = 9px, 9/16 = 56.25%;
    font-size: 45%;
  }

  @include respond(tab-port) {
    //width < 900?
    font-size: 40%; // 1 rem = 8px, 8/16 = 50%;
  }

  @include respond(phone) {
    // width < 600?
    //font-size: 56.25%; // 1 rem = 9px, 9/16 = 56.25%;
    font-size: 35%;
  }

  @include respond(big-desktop) {
    // width > 1800?
    font-size: 75%; // 1 rem = 12px, 12/16 = 75%;
  }
}

body {
  box-sizing: border-box;
  //padding: 0rem;
}
